
export default function Background({gradient = 'linear-gradient(92deg, #F1F6FF 1.08%, #FFF4F4 58.78%, #FFF6ED 100%)'}: {gradient?: string}) {
    return <div
        className="w-full h-[150px] overflow-hidden md:h-[250px]"
        style={{background: gradient}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="1280" height="256" viewBox="0 0 1280 256" fill="none">
            <path d="M-23.8973 77.4997C78.7694 81.1664 288.403 124.8 363.603 274C457.603 460.5 634.603 161.5 882.103 77.4997C1129.6 -6.50027 1166.1 -18.0002 1241.6 -18.0002C1317.1 -18.0002 1383.1 75.5785 1304.1 151.5C1175.6 275 976.519 241.668 912.065 225.929C840.6 208.478 940.103 115.501 961.603 151.5C1031.19 268.022 626.603 51.4998 669.603 -18.0002C712.603 -87.5002 503.103 -31.0003 478.603 133.5C454.103 298 446.103 332.5 363.603 331C281.103 329.5 391.103 157 740.603 -18.0002C1090.1 -193 402.103 -42.5002 295.103 77.4997C188.103 197.5 -21.8973 199 -64.8973 151.5C-107.897 104 88.6027 -74 141.103 -33C193.603 8.00002 369.597 655.492 1333.6 -4.99995C1411.1 -58.0994 1361.6 64.001 1304.1 105.5C1243.19 87.9331 1259.1 10.3257 1070.6 87.501C882.103 164.676 553.926 -68.7433 355.603 -22.999C19.6027 54.5012 570.103 139.501 917.103 33C1005.02 6.01512 1125.6 -69.6667 1175.6 -102" stroke="white" strokeWidth="5"/>
        </svg>
    </div>
}
