 const coin_types = [
    [0x80000000, 'BTC', 'Bitcoin'],
    [0x80000001, '', 'Testnet (all coins)'],
    [0x80000002, 'LTC', 'Litecoin'],
    [0x80000003, 'DOGE', 'Dogecoin'],
    [0x80000004, 'RDD', 'Reddcoin'],
    [0x80000005, 'DASH', 'Dash'],
    [0x80000006, 'PPC', 'Peercoin'],
    [0x80000007, 'NMC', 'Namecoin'],
    [0x80000008, 'FTC', 'Feathercoin'],
    [0x80000009, 'XCP', 'Counterparty'],
    [0x8000000a, 'BLK', 'Blackcoin'],
    [0x8000000b, 'NSR', 'NuShares'],
    [0x8000000c, 'NBT', 'NuBits'],
    [0x8000000d, 'MZC', 'Mazacoin'],
    [0x8000000e, 'VIA', 'Viacoin'],
    [0x8000000f, 'XCH', 'ClearingHouse'],
    [0x80000010, 'RBY', 'Rubycoin'],
    [0x80000011, 'GRS', 'Groestlcoin'],
    [0x80000012, 'DGC', 'Digitalcoin'],
    [0x80000013, 'CCN', 'Cannacoin'],
    [0x80000014, 'DGB', 'DigiByte'],
    [0x80000015, '', 'Open Assets'],
    [0x80000016, 'MONA', 'Monacoin'],
    [0x80000017, 'CLAM', 'Clams'],
    [0x80000018, 'XPM', 'Primecoin'],
    [0x80000019, 'NEOS', 'Neoscoin'],
    [0x8000001a, 'JBS', 'Jumbucks'],
    [0x8000001b, 'ZRC', 'ziftrCOIN'],
    [0x8000001c, 'VTC', 'Vertcoin'],
    [0x8000001d, 'NXT', 'NXT'],
    [0x8000001e, 'BURST', 'Burst'],
    [0x8000001f, 'MUE', 'MonetaryUnit'],
    [0x80000020, 'ZOOM', 'Zoom'],
    [0x80000021, 'VASH', 'Virtual Cash'],
    [0x80000022, 'CDN', 'Canada eCoin'],
    [0x80000023, 'SDC', 'ShadowCash'],
    [0x80000024, 'PKB', 'ParkByte'],
    [0x80000025, 'PND', 'Pandacoin'],
    [0x80000026, 'START', 'StartCOIN'],
    [0x80000027, 'MOIN', 'MOIN'],
    [0x80000028, 'EXP', 'Expanse'],
    [0x80000029, 'EMC2', 'Einsteinium'],
    [0x8000002a, 'DCR', 'Decred'],
    [0x8000002b, 'XEM', 'NEM'],
    [0x8000002c, 'PART', 'Particl'],
    [0x8000002d, 'ARG', 'Argentum (dead)'],
    [0x8000002e, '', 'Libertas'],
    [0x8000002f, '', 'Posw coin'],
    [0x80000030, 'SHR', 'Shreeji'],
    [0x80000031, 'GCR', 'Global Currency Reserve (GCRcoin)'],
    [0x80000032, 'NVC', 'Novacoin'],
    [0x80000033, 'AC', 'Asiacoin'],
    [0x80000034, 'BTCD', 'BitcoinDark'],
    [0x80000035, 'DOPE', 'Dopecoin'],
    [0x80000036, 'TPC', 'Templecoin'],
    [0x80000037, 'AIB', 'AIB'],
    [0x80000038, 'EDRC', 'EDRCoin'],
    [0x80000039, 'SYS', 'Syscoin'],
    [0x8000003a, 'SLR', 'Solarcoin'],
    [0x8000003b, 'SMLY', 'Smileycoin'],
    [0x8000003c, 'ETH', 'Ether'],
    [0x8000003d, 'ETC', 'Ether Classic'],
    [0x8000003e, 'PSB', 'Pesobit'],
    [0x8000003f, 'LDCN', 'Landcoin (dead)'],
    [0x80000040, '', 'Open Chain'],
    [0x80000041, 'XBC', 'Bitcoinplus'],
    [0x80000042, 'IOP', 'Internet of People'],
    [0x80000043, 'NXS', 'Nexus'],
    [0x80000044, 'INSN', 'InsaneCoin'],
    [0x80000045, 'OK', 'OKCash'],
    [0x80000046, 'BRIT', 'BritCoin'],
    [0x80000047, 'CMP', 'Compcoin'],
    [0x80000048, 'CRW', 'Crown'],
    [0x80000049, 'BELA', 'BelaCoin'],
    [0x8000004a, 'ICX', 'ICON'],
    [0x8000004b, 'FJC', 'FujiCoin'],
    [0x8000004c, 'MIX', 'MIX'],
    [0x8000004d, 'XVG', 'Verge Currency'],
    [0x8000004e, 'EFL', 'Electronic Gulden'],
    [0x8000004f, 'CLUB', 'ClubCoin'],
    [0x80000050, 'RICHX', 'RichCoin'],
    [0x80000051, 'POT', 'Potcoin'],
    [0x80000052, 'QRK', 'Quarkcoin'],
    [0x80000053, 'TRC', 'Terracoin'],
    [0x80000054, 'GRC', 'Gridcoin'],
    [0x80000055, 'AUR', 'Auroracoin'],
    [0x80000056, 'IXC', 'IXCoin'],
    [0x80000057, 'NLG', 'Gulden'],
    [0x80000058, 'BITB', 'BitBean'],
    [0x80000059, 'BTA', 'Bata'],
    [0x8000005a, 'XMY', 'Myriadcoin'],
    [0x8000005b, 'BSD', 'BitSend'],
    [0x8000005c, 'UNO', 'Unobtanium'],
    [0x8000005d, 'MTR', 'MasterTrader'],
    [0x8000005e, 'GB', 'GoldBlocks'],
    [0x8000005f, 'SHM', 'Saham'],
    [0x80000060, 'CRX', 'Chronos'],
    [0x80000061, 'BIQ', 'Ubiquoin'],
    [0x80000062, 'EVO', 'Evotion'],
    [0x80000063, 'STO', 'SaveTheOcean'],
    [0x80000064, 'BIGUP', 'BigUp'],
    [0x80000065, 'GAME', 'GameCredits'],
    [0x80000066, 'DLC', 'Dollarcoins'],
    [0x80000067, 'ZYD', 'Zayedcoin'],
    [0x80000068, 'DBIC', 'Dubaicoin'],
    [0x80000069, 'STRAT', 'Stratis'],
    [0x8000006a, 'SH', 'Shilling'],
    [0x8000006b, 'MARS', 'MarsCoin'],
    [0x8000006c, 'UBQ', 'Ubiq'],
    [0x8000006d, 'PTC', 'Pesetacoin'],
    [0x8000006e, 'NRO', 'Neurocoin'],
    [0x8000006f, 'ARK', 'ARK'],
    [0x80000070, 'USC', 'UltimateSecureCashMain'],
    [0x80000071, 'THC', 'Hempcoin'],
    [0x80000072, 'LINX', 'Linx'],
    [0x80000073, 'ECN', 'Ecoin'],
    [0x80000074, 'DNR', 'Denarius'],
    [0x80000075, 'PINK', 'Pinkcoin'],
    [0x80000076, 'ATOM', 'Atom'],
    [0x80000077, 'PIVX', 'Pivx'],
    [0x80000078, 'FLASH', 'Flashcoin'],
    [0x80000079, 'ZEN', 'Zencash'],
    [0x8000007a, 'PUT', 'Putincoin'],
    [0x8000007b, 'ZNY', 'BitZeny'],
    [0x8000007c, 'UNIFY', 'Unify'],
    [0x8000007d, 'XST', 'StealthCoin'],
    [0x8000007e, 'BRK', 'Breakout Coin'],
    [0x8000007f, 'VC', 'Vcash'],
    [0x80000080, 'XMR', 'Monero'],
    [0x80000081, 'VOX', 'Voxels'],
    [0x80000082, 'NAV', 'NavCoin'],
    [0x80000083, 'FCT', 'Factom Factoids'],
    [0x80000084, 'EC', 'Factom Entry Credits'],
    [0x80000085, 'ZEC', 'Zcash'],
    [0x80000086, 'LSK', 'Lisk'],
    [0x80000087, 'STEEM', 'Steem'],
    [0x80000088, 'XZC', 'ZCoin'],
    [0x80000089, 'RBTC', 'Rootstock'],
    [0x8000008a, '', 'Giftblock'],
    [0x8000008b, 'RPT', 'RealPointCoin'],
    [0x8000008c, 'LBC', 'LBRY Credits'],
    [0x8000008d, 'KMD', 'Komodo'],
    [0x8000008e, 'BSQ', 'bisq Token'],
    [0x8000008f, 'RIC', 'Riecoin'],
    [0x80000090, 'XRP', 'Ripple'],
    [0x80000091, 'BCH', 'Bitcoin Cash'],
    [0x80000092, 'NEBL', 'Neblio'],
    [0x80000093, 'ZCL', 'ZClassic'],
    [0x80000094, 'XLM', 'Stellar Lumens'],
    [0x80000095, 'NLC2', 'NoLimitCoin2'],
    [0x80000096, 'WHL', 'WhaleCoin'],
    [0x80000097, 'ERC', 'EuropeCoin'],
    [0x80000098, 'DMD', 'Diamond'],
    [0x80000099, 'BTM', 'Bytom'],
    [0x8000009a, 'BIO', 'Biocoin'],
    [0x8000009b, 'XWCC', 'Whitecoin Classic'],
    [0x8000009c, 'BTG', 'Bitcoin Gold'],
    [0x8000009d, 'BTC2X', 'Bitcoin 2x'],
    [0x8000009e, 'SSN', 'SuperSkynet'],
    [0x8000009f, 'TOA', 'TOACoin'],
    [0x800000a0, 'BTX', 'Bitcore'],
    [0x800000a1, 'ACC', 'Adcoin'],
    [0x800000a2, 'BCO', 'Bridgecoin'],
    [0x800000a3, 'ELLA', 'Ellaism'],
    [0x800000a4, 'PIRL', 'Pirl'],
    [0x800000a5, 'XNO', 'Nano'],
    [0x800000a6, 'VIVO', 'Vivo'],
    [0x800000a7, 'FRST', 'Firstcoin'],
    [0x800000a8, 'HNC', 'Helleniccoin'],
    [0x800000a9, 'BUZZ', 'BUZZ'],
    [0x800000aa, 'MBRS', 'Ember'],
    [0x800000ab, 'HC', 'Hcash'],
    [0x800000ac, 'HTML', 'HTMLCOIN'],
    [0x800000ad, 'ODN', 'Obsidian'],
    [0x800000ae, 'ONX', 'OnixCoin'],
    [0x800000af, 'RVN', 'Ravencoin'],
    [0x800000b0, 'GBX', 'GoByte'],
    [0x800000b1, 'BTCZ', 'BitcoinZ'],
    [0x800000b2, 'POA', 'Poa'],
    [0x800000b3, 'NYC', 'NewYorkCoin'],
    [0x800000b4, 'MXT', 'MarteXcoin'],
    [0x800000b5, 'WC', 'Wincoin'],
    [0x800000b6, 'MNX', 'Minexcoin'],
    [0x800000b7, 'BTCP', 'Bitcoin Private'],
    [0x800000b8, 'MUSIC', 'Musicoin'],
    [0x800000b9, 'BCA', 'Bitcoin Atom'],
    [0x800000ba, 'CRAVE', 'Crave'],
    [0x800000bb, 'STAK', 'STRAKS'],
    [0x800000bc, 'WBTC', 'World Bitcoin'],
    [0x800000bd, 'LCH', 'LiteCash'],
    [0x800000be, 'EXCL', 'ExclusiveCoin'],
    [0x800000bf, '', 'Lynx'],
    [0x800000c0, 'LCC', 'LitecoinCash'],
    [0x800000c1, 'XFE', 'Feirm'],
    [0x800000c2, 'EOS', 'EOS'],
    [0x800000c3, 'TRX', 'Tron'],
    [0x800000c4, 'KOBO', 'Kobocoin'],
    [0x800000c5, 'HUSH', 'HUSH'],
    [0x800000c6, 'BAN', 'Banano'],
    [0x800000c7, 'ETF', 'ETF'],
    [0x800000c8, 'OMNI', 'Omni'],
    [0x800000c9, 'BIFI', 'BitcoinFile'],
    [0x800000ca, 'UFO', 'Uniform Fiscal Object'],
    [0x800000cb, 'CNMC', 'Cryptonodes'],
    [0x800000cc, 'BCN', 'Bytecoin'],
    [0x800000cd, 'RIN', 'Ringo'],
    [0x800000ce, 'ATP', 'Alaya'],
    [0x800000cf, 'EVT', 'everiToken'],
    [0x800000d0, 'ATN', 'ATN'],
    [0x800000d1, 'BIS', 'Bismuth'],
    [0x800000d2, 'NEET', 'NEETCOIN'],
    [0x800000d3, 'BOPO', 'BopoChain'],
    [0x800000d4, 'OOT', 'Utrum'],
    [0x800000d5, 'ALIAS', 'Alias'],
    [0x800000d6, 'MONK', 'Monkey Project'],
    [0x800000d7, 'BOXY', 'BoxyCoin'],
    [0x800000d8, 'FLO', 'Flo'],
    [0x800000d9, 'MEC', 'Megacoin'],
    [0x800000da, 'BTDX', 'BitCloud'],
    [0x800000db, 'XAX', 'Artax'],
    [0x800000dc, 'ANON', 'ANON'],
    [0x800000dd, 'LTZ', 'LitecoinZ'],
    [0x800000de, 'BITG', 'Bitcoin Green'],
    [0x800000df, 'ICP', 'Internet Computer (DFINITY)'],
    [0x800000e0, 'SMART', 'Smartcash'],
    [0x800000e1, 'XUEZ', 'XUEZ'],
    [0x800000e2, 'HLM', 'Helium'],
    [0x800000e3, 'WEB', 'Webchain'],
    [0x800000e4, 'ACM', 'Actinium'],
    [0x800000e5, 'NOS', 'NOS Stable Coins'],
    [0x800000e6, 'BITC', 'BitCash'],
    [0x800000e7, 'HTH', 'Help The Homeless Coin'],
    [0x800000e8, 'TZC', 'Trezarcoin'],
    [0x800000e9, 'VAR', 'Varda'],
    [0x800000ea, 'IOV', 'IOV'],
    [0x800000eb, 'FIO', 'FIO'],
    [0x800000ec, 'BSV', 'BitcoinSV'],
    [0x800000ed, 'DXN', 'DEXON'],
    [0x800000ee, 'QRL', 'Quantum Resistant Ledger'],
    [0x800000ef, 'PCX', 'ChainX'],
    [0x800000f0, 'LOKI', 'Loki'],
    [0x800000f1, '', 'Imagewallet'],
    [0x800000f2, 'NIM', 'Nimiq'],
    [0x800000f3, 'SOV', 'Sovereign Coin'],
    [0x800000f4, 'JCT', 'Jibital Coin'],
    [0x800000f5, 'SLP', 'Simple Ledger Protocol'],
    [0x800000f6, 'EWT', 'Energy Web'],
    [0x800000f7, 'UC', 'Ulord'],
    [0x800000f8, 'EXOS', 'EXOS'],
    [0x800000f9, 'ECA', 'Electra'],
    [0x800000fa, 'SOOM', 'Soom'],
    [0x800000fb, 'XRD', 'Redstone'],
    [0x800000fc, 'FREE', 'FreeCoin'],
    [0x800000fd, 'NPW', 'NewPowerCoin'],
    [0x800000fe, 'BST', 'BlockStamp'],
    [0x800000ff, '', 'SmartHoldem'],
    [0x80000100, 'NANO', 'Bitcoin Nano'],
    [0x80000101, 'BTCC', 'Bitcoin Core'],
    [0x80000102, '', 'Zen Protocol'],
    [0x80000103, 'ZEST', 'Zest'],
    [0x80000104, 'ABT', 'ArcBlock'],
    [0x80000105, 'PION', 'Pion'],
    [0x80000106, 'DT3', 'DreamTeam3'],
    [0x80000107, 'ZBUX', 'Zbux'],
    [0x80000108, 'KPL', 'Kepler'],
    [0x80000109, 'TPAY', 'TokenPay'],
    [0x8000010a, 'ZILLA', 'ChainZilla'],
    [0x8000010b, 'ANK', 'Anker'],
    [0x8000010c, 'BCC', 'BCChain'],
    [0x8000010d, 'HPB', 'HPB'],
    [0x8000010e, 'ONE', 'ONE'],
    [0x8000010f, 'SBC', 'SBC'],
    [0x80000110, 'IPC', 'IPChain'],
    [0x80000111, 'DMTC', 'Dominantchain'],
    [0x80000112, 'OGC', 'Onegram'],
    [0x80000113, 'SHIT', 'Shitcoin'],
    [0x80000114, 'ANDES', 'Andescoin'],
    [0x80000115, 'AREPA', 'Arepacoin'],
    [0x80000116, 'BOLI', 'Bolivarcoin'],
    [0x80000117, 'RIL', 'Rilcoin'],
    [0x80000118, 'HTR', 'Hathor Network'],
    [0x80000119, 'ACME', 'Accumulate'],
    [0x8000011a, 'BRAVO', 'BRAVO'],
    [0x8000011b, 'ALGO', 'Algorand'],
    [0x8000011c, 'BZX', 'Bitcoinzero'],
    [0x8000011d, 'GXX', 'GravityCoin'],
    [0x8000011e, 'HEAT', 'HEAT'],
    [0x8000011f, 'XDN', 'DigitalNote'],
    [0x80000120, 'FSN', 'FUSION'],
    [0x80000121, 'CPC', 'Capricoin'],
    [0x80000122, 'BOLD', 'Bold'],
    [0x80000123, 'IOST', 'IOST'],
    [0x80000124, 'TKEY', 'Tkeycoin'],
    [0x80000125, 'USE', 'Usechain'],
    [0x80000126, 'BCZ', 'BitcoinCZ'],
    [0x80000127, 'IOC', 'Iocoin'],
    [0x80000128, 'ASF', 'Asofe'],
    [0x80000129, 'MASS', 'MASS'],
    [0x8000012a, 'FAIR', 'FairCoin'],
    [0x8000012b, 'NUKO', 'Nekonium'],
    [0x8000012c, 'GNX', 'Genaro Network'],
    [0x8000012d, 'DIVI', 'Divi Project'],
    [0x8000012e, 'CMT', 'Community'],
    [0x8000012f, 'EUNO', 'EUNO'],
    [0x80000130, 'IOTX', 'IoTeX'],
    [0x80000131, 'ONION', 'DeepOnion'],
    [0x80000132, '8BIT', '8Bit'],
    [0x80000133, 'ATC', 'AToken Coin'],
    [0x80000134, 'BTS', 'Bitshares'],
    [0x80000135, 'CKB', 'Nervos CKB'],
    [0x80000136, 'UGAS', 'Ultrain'],
    [0x80000137, 'ADS', 'Adshares'],
    [0x80000138, 'ARA', 'Aura'],
    [0x80000139, 'ZIL', 'Zilliqa'],
    [0x8000013a, 'MOAC', 'MOAC'],
    [0x8000013b, 'SWTC', 'SWTC'],
    [0x8000013c, 'VNSC', 'vnscoin'],
    [0x8000013d, 'PLUG', 'Pl^g'],
    [0x8000013e, 'MAN', 'Matrix AI Network'],
    [0x8000013f, 'ECC', 'ECCoin'],
    [0x80000140, 'RPD', 'Rapids'],
    [0x80000141, 'RAP', 'Rapture'],
    [0x80000142, 'GARD', 'Hashgard'],
    [0x80000143, 'ZER', 'Zero'],
    [0x80000144, 'EBST', 'eBoost'],
    [0x80000145, 'SHARD', 'Shard'],
    [0x80000146, 'MRX', 'Metrix Coin'],
    [0x80000147, 'CMM', 'Commercium'],
    [0x80000148, 'BLOCK', 'Blocknet'],
    [0x80000149, 'AUDAX', 'AUDAX'],
    [0x8000014a, 'LUNA', 'Terra'],
    [0x8000014b, 'ZPM', 'zPrime'],
    [0x8000014c, 'KUVA', 'Kuva Utility Note'],
    [0x8000014d, 'MEM', 'MemCoin'],
    [0x8000014e, 'CS', 'Credits'],
    [0x8000014f, 'SWIFT', 'SwiftCash'],
    [0x80000150, 'FIX', 'FIX'],
    [0x80000151, 'CPC', 'CPChain'],
    [0x80000152, 'VGO', 'VirtualGoodsToken'],
    [0x80000153, 'DVT', 'DeVault'],
    [0x80000154, 'N8V', 'N8VCoin'],
    [0x80000155, 'MTNS', 'OmotenashiCoin'],
    [0x80000156, 'BLAST', 'BLAST'],
    [0x80000157, 'DCT', 'DECENT'],
    [0x80000158, 'AUX', 'Auxilium'],
    [0x80000159, 'USDP', 'USDP'],
    [0x8000015a, 'HTDF', 'HTDF'],
    [0x8000015b, 'YEC', 'Ycash'],
    [0x8000015c, 'QLC', 'QLC Chain'],
    [0x8000015d, 'TEA', 'Icetea Blockchain'],
    [0x8000015e, 'ARW', 'ArrowChain'],
    [0x8000015f, 'MDM', 'Medium'],
    [0x80000160, 'CYB', 'Cybex'],
    [0x80000161, 'LTO', 'LTO Network'],
    [0x80000162, 'DOT', 'Polkadot'],
    [0x80000163, 'AEON', 'Aeon'],
    [0x80000164, 'RES', 'Resistance'],
    [0x80000165, 'AYA', 'Aryacoin'],
    [0x80000166, 'DAPS', 'Dapscoin'],
    [0x80000167, 'CSC', 'CasinoCoin'],
    [0x80000168, 'VSYS', 'V Systems'],
    [0x80000169, 'NOLLAR', 'Nollar'],
    [0x8000016a, 'XNOS', 'NOS'],
    [0x8000016b, 'CPU', 'CPUchain'],
    [0x8000016c, 'LAMB', 'Lambda Storage Chain'],
    [0x8000016d, 'VCT', 'ValueCyber'],
    [0x8000016e, 'CZR', 'Canonchain'],
    [0x8000016f, 'ABBC', 'ABBC'],
    [0x80000170, 'HET', 'HET'],
    [0x80000171, 'XAS', 'Asch'],
    [0x80000172, 'VDL', 'Vidulum'],
    [0x80000173, 'MED', 'MediBloc'],
    [0x80000174, 'ZVC', 'ZVChain'],
    [0x80000175, 'VESTX', 'Vestx'],
    [0x80000176, 'DBT', 'DarkBit'],
    [0x80000177, 'SEOS', 'SuperEOS'],
    [0x80000178, 'MXW', 'Maxonrow'],
    [0x80000179, 'ZNZ', 'ZENZO'],
    [0x8000017a, 'XCX', 'XChain'],
    [0x8000017b, 'SOX', 'SonicX'],
    [0x8000017c, 'NYZO', 'Nyzo'],
    [0x8000017d, 'ULC', 'ULCoin'],
    [0x8000017e, 'RYO', 'Ryo Currency'],
    [0x8000017f, 'KAL', 'Kaleidochain'],
    [0x80000180, 'XSN', 'Stakenet'],
    [0x80000181, 'DOGEC', 'DogeCash'],
    [0x80000182, 'BMV', 'Bitcoin Matteo\'s Vision'],
    [0x80000183, 'QBC', 'Quebecoin'],
    [0x80000184, 'IMG', 'ImageCoin'],
    [0x80000185, 'QOS', 'QOS'],
    [0x80000186, 'PKT', 'PKT'],
    [0x80000187, 'LHD', 'LitecoinHD'],
    [0x80000188, 'CENNZ', 'CENNZnet'],
    [0x80000189, 'HSN', 'Hyper Speed Network'],
    [0x8000018a, 'CRO', 'Crypto Chain'],
    [0x8000018b, 'UMBRU', 'Umbru'],
    [0x8000018c, 'EVER', 'Everscale'],
    [0x8000018d, 'NEAR', 'NEAR Protocol'],
    [0x8000018e, 'XPC', 'XPChain'],
    [0x8000018f, 'ZOC', '01coin'],
    [0x80000190, 'NIX', 'NIX'],
    [0x80000191, 'UC', 'Utopiacoin'],
    [0x80000192, 'GALI', 'Galilel'],
    [0x80000193, 'OLT', 'Oneledger'],
    [0x80000194, 'XBI', 'XBI'],
    [0x80000195, 'DONU', 'DONU'],
    [0x80000196, 'EARTHS', 'Earths'],
    [0x80000197, 'HDD', 'HDDCash'],
    [0x80000198, 'SUGAR', 'Sugarchain'],
    [0x80000199, 'AILE', 'AileCoin'],
    [0x8000019a, 'TENT', 'TENT'],
    [0x8000019b, 'TAN', 'Tangerine Network'],
    [0x8000019c, 'AIN', 'AIN'],
    [0x8000019d, 'MSR', 'Masari'],
    [0x8000019e, 'SUMO', 'Sumokoin'],
    [0x8000019f, 'ETN', 'Electroneum'],
    [0x800001a0, 'BYTZ', 'BYTZ'],
    [0x800001a1, 'WOW', 'Wownero'],
    [0x800001a2, 'XTNC', 'XtendCash'],
    [0x800001a3, 'LTHN', 'Lethean'],
    [0x800001a4, 'NODE', 'NodeHost'],
    [0x800001a5, 'AGM', 'Argoneum'],
    [0x800001a6, 'CCX', 'Conceal Network'],
    [0x800001a7, 'TNET', 'Title Network'],
    [0x800001a8, 'TELOS', 'TelosCoin'],
    [0x800001a9, 'AION', 'Aion'],
    [0x800001aa, 'BC', 'Bitcoin Confidential'],
    [0x800001ab, 'KTV', 'KmushiCoin'],
    [0x800001ac, 'ZCR', 'ZCore'],
    [0x800001ad, 'ERG', 'Ergo'],
    [0x800001ae, 'PESO', 'Criptopeso'],
    [0x800001af, 'BTC2', 'Bitcoin 2'],
    [0x800001b0, 'XRPHD', 'XRPHD'],
    [0x800001b1, 'WE', 'WE Coin'],
    [0x800001b2, 'KSM', 'Kusama'],
    [0x800001b3, 'PCN', 'Peepcoin'],
    [0x800001b4, 'NCH', 'NetCloth'],
    [0x800001b5, 'ICU', 'CHIPO'],
    [0x800001b6, 'FNSA', 'FINSCHIA'],
    [0x800001b7, 'DTP', 'DeVault Token Protocol'],
    [0x800001b8, 'BTCR', 'Bitcoin Royale'],
    [0x800001b9, 'AERGO', 'AERGO'],
    [0x800001ba, 'XTH', 'Dothereum'],
    [0x800001bb, 'LV', 'Lava'],
    [0x800001bc, 'PHR', 'Phore'],
    [0x800001bd, 'VITAE', 'Vitae'],
    [0x800001be, 'COCOS', 'Cocos-BCX'],
    [0x800001bf, 'DIN', 'Dinero'],
    [0x800001c0, 'SPL', 'Simplicity'],
    [0x800001c1, 'YCE', 'MYCE'],
    [0x800001c2, 'XLR', 'Solaris'],
    [0x800001c3, 'KTS', 'Klimatas'],
    [0x800001c4, 'DGLD', 'DGLD'],
    [0x800001c5, 'XNS', 'Insolar'],
    [0x800001c6, 'EM', 'EMPOW'],
    [0x800001c7, 'SHN', 'ShineBlocks'],
    [0x800001c8, 'SEELE', 'Seele'],
    [0x800001c9, 'AE', 'æternity'],
    [0x800001ca, 'ODX', 'ObsidianX'],
    [0x800001cb, 'KAVA', 'Kava'],
    [0x800001cc, 'GLEEC', 'GLEEC'],
    [0x800001cd, 'FIL', 'Filecoin'],
    [0x800001ce, 'RUTA', 'Rutanio'],
    [0x800001cf, 'CSDT', 'CSDT'],
    [0x800001d0, 'ETI', 'EtherInc'],
    [0x800001d1, 'ZSLP', 'Zclassic Simple Ledger Protocol'],
    [0x800001d2, 'ERE', 'EtherCore'],
    [0x800001d3, 'DX', 'DxChain Token'],
    [0x800001d4, 'CPS', 'Capricoin+'],
    [0x800001d5, 'BTH', 'Bithereum'],
    [0x800001d6, 'MESG', 'MESG'],
    [0x800001d7, 'FIMK', 'FIMK'],
    [0x800001d8, 'AR', 'Arweave'],
    [0x800001d9, 'OGO', 'Origo'],
    [0x800001da, 'ROSE', 'Oasis Network'],
    [0x800001db, 'BARE', 'BARE Network'],
    [0x800001dc, 'GLEEC', 'GleecBTC'],
    [0x800001dd, 'CLR', 'Color Coin'],
    [0x800001de, 'RNG', 'Ring'],
    [0x800001df, 'OLO', 'Tool Global'],
    [0x800001e0, 'PEXA', 'Pexa'],
    [0x800001e1, 'MOON', 'Mooncoin'],
    [0x800001e2, 'OCEAN', 'Ocean Protocol'],
    [0x800001e3, 'BNT', 'Bluzelle Native'],
    [0x800001e4, 'AMO', 'AMO Blockchain'],
    [0x800001e5, 'FCH', 'FreeCash'],
    [0x800001e6, 'LAT', 'PlatON'],
    [0x800001e7, 'COIN', 'Bitcoin Bank'],
    [0x800001e8, 'VEO', 'Amoveo'],
    [0x800001e9, 'CCA', 'Counos Coin'],
    [0x800001ea, 'GFN', 'Graphene'],
    [0x800001eb, 'BIP', 'Minter Network'],
    [0x800001ec, 'KPG', 'Kunpeng Network'],
    [0x800001ed, 'FIN', 'FINL Chain'],
    [0x800001ee, 'BAND', 'Band'],
    [0x800001ef, 'DROP', 'Dropil'],
    [0x800001f0, 'BHT', 'Bluehelix Chain'],
    [0x800001f1, 'LYRA', 'Scrypta'],
    [0x800001f2, 'CS', 'Credits'],
    [0x800001f3, 'RUPX', 'Rupaya'],
    [0x800001f4, 'THETA', 'Theta'],
    [0x800001f5, 'SOL', 'Solana'],
    [0x800001f6, 'THT', 'ThoughtAI'],
    [0x800001f7, 'CFX', 'Conflux'],
    [0x800001f8, 'KUMA', 'Kumacoin'],
    [0x800001f9, 'HASH', 'Provenance'],
    [0x800001fa, 'CSPR', 'Casper'],
    [0x800001fb, 'EARTH', 'EARTH'],
    [0x800001fc, 'EGLD', 'MultiversX'],
    [0x800001fd, 'CHI', 'Xaya'],
    [0x800001fe, 'KOTO', 'Koto'],
    [0x800001ff, 'OTC', 'θ'],
    [0x80000200, 'RXD', 'Radiant'],
    [0x80000201, 'SEELEN', 'Seele-N'],
    [0x80000202, 'AETH', 'AETH'],
    [0x80000203, 'DNA', 'Idena'],
    [0x80000204, 'VEE', 'Virtual Economy Era'],
    [0x80000205, 'SIERRA', 'SierraCoin'],
    [0x80000206, 'LET', 'Linkeye'],
    [0x80000207, 'BSC', 'Bitcoin Smart Contract'],
    [0x80000208, 'BTCV', 'BitcoinVIP'],
    [0x80000209, 'ABA', 'Dabacus'],
    [0x8000020a, 'SCC', 'StakeCubeCoin'],
    [0x8000020b, 'EDG', 'Edgeware'],
    [0x8000020c, 'AMS', 'AmsterdamCoin'],
    [0x8000020d, 'GOSS', 'GOSSIP Coin'],
    [0x8000020e, 'BU', 'BUMO'],
    [0x8000020f, 'GRAM', 'GRAM'],
    [0x80000210, 'YAP', 'Yapstone'],
    [0x80000211, 'SCRT', 'Secret Network'],
    [0x80000212, 'NOVO', 'Novo'],
    [0x80000213, 'GHOST', 'Ghost'],
    [0x80000214, 'HST', 'HST'],
    [0x80000215, 'PRJ', 'ProjectCoin'],
    [0x80000216, 'YOU', 'YOUChain'],
    [0x80000217, 'XHV', 'Haven Protocol'],
    [0x80000218, 'BYND', 'Beyondcoin'],
    [0x80000219, 'JOYS', 'Joys Digital'],
    [0x8000021a, 'VAL', 'Valorbit'],
    [0x8000021b, 'FLOW', 'Flow'],
    [0x8000021c, 'SMESH', 'Spacemesh Coin'],
    [0x8000021d, 'SCDO', 'SCDO'],
    [0x8000021e, 'IQS', 'IQ-Cash'],
    [0x8000021f, 'BIND', 'Compendia'],
    [0x80000220, 'COINEVO', 'Coinevo'],
    [0x80000221, 'SCRIBE', 'Scribe'],
    [0x80000222, 'HYN', 'Hyperion'],
    [0x80000223, 'BHP', 'BHP'],
    [0x80000224, 'BBC', 'BigBang Core'],
    [0x80000225, 'MKF', 'MarketFinance'],
    [0x80000226, 'XDC', 'XinFin'],
    [0x80000227, 'STR', 'Straightedge'],
    [0x80000228, 'SUM', 'Sumcoin'],
    [0x80000229, 'HBC', 'HuobiChain'],
    [0x8000022a, '---', 'reserved'],
    [0x8000022b, 'BCS', 'Bitcoin Smart'],
    [0x8000022c, 'KTS', 'Kratos'],
    [0x8000022d, 'LKR', 'Lkrcoin'],
    [0x8000022e, 'TAO', 'Tao'],
    [0x8000022f, 'XWC', 'Whitecoin'],
    [0x80000230, 'DEAL', 'DEAL'],
    [0x80000231, 'NTY', 'Nexty'],
    [0x80000232, 'TOP', 'TOP NetWork'],
    [0x80000233, '---', 'reserved'],
    [0x80000234, 'AG', 'Agoric'],
    [0x80000235, 'CICO', 'Coinicles'],
    [0x80000236, 'IRIS', 'Irisnet'],
    [0x80000237, 'NCG', 'Nine Chronicles'],
    [0x80000238, 'LRG', 'Large Coin'],
    [0x80000239, 'SERO', 'Super Zero Protocol'],
    [0x8000023a, 'BDX', 'Beldex'],
    [0x8000023b, 'CCXX', 'Counos X'],
    [0x8000023c, 'SLS', 'Saluscoin'],
    [0x8000023d, 'SRM', 'Serum'],
    [0x8000023e, '---', 'reserved'],
    [0x8000023f, 'VIVT', 'VIDT Datalink'],
    [0x80000240, 'BPS', 'BitcoinPoS'],
    [0x80000241, 'NKN', 'NKN'],
    [0x80000242, 'ICL', 'ILCOIN'],
    [0x80000243, 'BONO', 'Bonorum'],
    [0x80000244, 'PLC', 'PLATINCOIN'],
    [0x80000245, 'DUN', 'Dune'],
    [0x80000246, 'DMCH', 'Darmacash'],
    [0x80000247, 'CTC', 'Creditcoin'],
    [0x80000248, 'KELP', 'Haidai Network'],
    [0x80000249, 'GBCR', 'GoldBCR'],
    [0x8000024a, 'XDAG', 'XDAG'],
    [0x8000024b, 'PRV', 'Incognito Privacy'],
    [0x8000024c, 'SCAP', 'SafeCapital'],
    [0x8000024d, 'TFUEL', 'Theta Fuel'],
    [0x8000024e, 'GTM', 'Gentarium'],
    [0x8000024f, 'RNL', 'RentalChain'],
    [0x80000250, 'GRIN', 'Grin'],
    [0x80000251, 'MWC', 'MimbleWimbleCoin'],
    [0x80000252, 'DOCK', 'Dock'],
    [0x80000253, 'POLYX', 'Polymesh'],
    [0x80000254, 'DIVER', 'Divergenti'],
    [0x80000255, 'XEP', 'Electra Protocol'],
    [0x80000256, 'APN', 'Apron'],
    [0x80000257, 'TFC', 'Turbo File Coin'],
    [0x80000258, 'UTE', 'Unit-e'],
    [0x80000259, 'MTC', 'Metacoin'],
    [0x8000025a, 'NC', 'NobodyCash'],
    [0x8000025b, 'XINY', 'Xinyuehu'],
    [0x8000025c, 'DYN', 'Dynamo'],
    [0x8000025d, 'BUFS', 'Buffer'],
    [0x8000025e, 'STOS', 'Stratos'],
    [0x8000025f, 'TON', 'TON'],
    [0x80000260, 'TAFT', 'TAFT'],
    [0x80000261, 'HYDRA', 'HYDRA'],
    [0x80000262, 'NOR', 'Noir'],
    [0x80000263, '', 'Manta Network Private Asset'],
    [0x80000264, '', 'Calamari Network Private Asset'],
    [0x80000265, 'WCN', 'Widecoin'],
    [0x80000266, 'OPT', 'Optimistic Ethereum'],
    [0x80000267, 'PSWAP', 'PolkaSwap'],
    [0x80000268, 'VAL', 'Validator'],
    [0x80000269, 'XOR', 'Sora'],
    [0x8000026a, 'SSP', 'SmartShare'],
    [0x8000026b, 'DEI', 'DeimosX'],
    [0x8000026c, '---', 'reserved'],
    [0x8000026d, 'ZERO', 'Singularity'],
    [0x8000026e, 'ALPHA', 'AlphaDAO'],
    [0x8000026f, 'BDECO', 'BDCashProtocol Ecosystem'],
    [0x80000270, 'NOBL', 'Nobility'],
    [0x80000271, 'EAST', 'Eastcoin'],
    [0x80000272, 'KDA', 'Kadena'],
    [0x80000273, 'SOUL', 'Phantasma'],
    [0x80000274, 'LORE', 'Gitopia'],
    [0x80000275, 'FNR', 'Fincor'],
    [0x80000276, 'NEXUS', 'Nexus'],
    [0x80000277, 'QTZ', 'Quartz'],
    [0x80000278, 'MAS', 'Massa'],
    [0x80000279, 'CALL', 'Callchain'],
    [0x8000027a, 'VAL', 'Validity'],
    [0x8000027b, 'POKT', 'Pocket Network'],
    [0x8000027c, 'EMIT', 'EMIT'],
    [0x8000027d, 'APTOS', 'Aptos'],
    [0x8000027e, 'ADON', 'ADON'],
    [0x8000027f, 'BTSG', 'BitSong'],
    [0x80000280, 'LFC', 'Leofcoin'],
    [0x80000281, 'KCS', 'KuCoin Shares'],
    [0x80000282, 'KCC', 'KuCoin Community Chain'],
    [0x80000283, 'AZERO', 'Aleph Zero'],
    [0x80000284, 'TREE', 'Tree'],
    [0x80000285, 'LX', 'Lynx'],
    [0x80000286, 'XLN', 'Lunarium'],
    [0x80000287, 'CIC', 'CIC Chain'],
    [0x80000288, 'ZRB', 'Zarb'],
    [0x80000289, '---', 'reserved'],
    [0x8000028a, 'UCO', 'Archethic'],
    [0x8000028b, 'SFX', 'Safex Cash'],
    [0x8000028c, 'SFT', 'Safex Token'],
    [0x8000028d, 'WSFX', 'Wrapped Safex Cash'],
    [0x8000028e, 'USDG', 'US Digital Gold'],
    [0x8000028f, 'WMP', 'WAMP'],
    [0x80000290, 'EKTA', 'Ekta'],
    [0x80000291, 'YDA', 'YadaCoin'],
    [0x80000292, 'WHIVE', 'Whive'],
    [0x80000293, 'KOIN', 'Koinos'],
    [0x80000294, 'PIRATE', 'PirateCash'],
    [0x80000295, 'UNQ', 'Unique'],
    [0x80000296, 'ULM', 'UltonSmartchain'],
    [0x80000297, 'SFRX', 'EtherGem Sapphire'],
    [0x80000298, 'BSTY', 'GlobalBoost-Y'],
    [0x80000299, 'IMP', 'Impact Protocol'],
    [0x8000029a, 'ACT', 'Achain'],
    [0x8000029b, 'PRKL', 'Perkle'],
    [0x8000029c, 'SSC', 'SelfSell'],
    [0x8000029d, 'GC', 'GateChain'],
    [0x8000029e, 'PLGR', 'Pledger'],
    [0x8000029f, 'MPLGR', 'Pledger'],
    [0x800002a0, 'KNOX', 'Knox'],
    [0x800002a1, 'ZED', 'ZED'],
    [0x800002a2, 'CNDL', 'Candle'],
    [0x800002a3, 'WLKR', 'Walker Crypto Innovation Index'],
    [0x800002a4, 'WLKRR', 'Walker'],
    [0x800002a5, 'YUNGE', 'Yunge'],
    [0x800002a6, 'Voken', 'Voken'],
    [0x800002a7, 'APL', 'Apollo'],
    [0x800002a8, 'Evrynet', 'Evrynet'],
    [0x800002a9, 'NENG', 'Nengcoin'],
    [0x800002aa, 'CHTA', 'Cheetahcoin'],
    [0x800002ab, 'ALEO', 'Aleo Network'],
    [0x800002ac, 'HMS', 'Hemis'],
    [0x800002ad, 'OAS', 'Oasys'],
    [0x800002ae, 'KAR', 'Karura Network'],
    [0x800002af, 'FLON', 'FullOn Network'],
    [0x800002b0, 'CET', 'CoinEx Chain'],
    [0x800002b1, 'XLINK', 'XLink Chain'],
    [0x800002b2, 'KLV', 'KleverChain'],
    [0x800002b3, 'TNT', 'Tangle'],
    [0x800002b6, 'VTBC', 'VTB Community'],
    [0x800002ba, 'VEIL', 'Veil'],
    [0x800002bb, 'GTB', 'GotaBit'],
    [0x800002bc, 'XDAI', 'xDai'],
    [0x800002bd, 'COM', 'Commercio'],
    [0x800002be, 'CCC', 'Commercio Cash Credit'],
    [0x800002bf, 'SNR', 'Sonr'],
    [0x800002c0, 'RAQ', 'Ra Quantum'],
    [0x800002c1, 'PEG', 'Pegasus Token'],
    [0x800002c2, 'LKG', 'Lionking'],
    [0x800002c3, 'MCOIN', 'Moneta Coin'],
    [0x800002c5, 'AVAIL', 'Avail'],
    [0x800002c6, 'FURY', 'Highbury'],
    [0x800002c7, 'CHC', 'Chaincoin'],
    [0x800002c8, 'SERF', 'Serfnet'],
    [0x800002c9, 'XTL', 'Katal Chain'],
    [0x800002ca, 'BNB', 'Binance'],
    [0x800002cb, 'SIN', 'Sinovate'],
    [0x800002cc, 'DLN', 'Delion'],
    [0x800002cd, 'BONTE', 'Bontecoin'],
    [0x800002ce, 'PEER', 'Peer'],
    [0x800002cf, 'ZET', 'Zetacoin'],
    [0x800002d0, 'ABY', 'Artbyte'],
    [0x800002d1, 'PGX', 'Mirai Chain'],
    [0x800002d2, 'IL8P', 'InfiniLooP'],
    [0x800002d3, 'VOI', 'Voi'],
    [0x800002d4, 'XVC', 'Vanillacash'],
    [0x800002d5, 'MCX', 'MultiCash'],
    [0x800002d7, 'BLU', 'BluCrates'],
    [0x800002d8, 'BFC', 'BFC'],
    [0x800002da, 'HEALIOS', 'Tenacity'],
    [0x800002db, 'BMK', 'Bitmark'],
    [0x800002de, 'DENTX', 'DENTNet'],
    [0x800002e1, 'ATOP', 'Financial Blockchain'],
    [0x800002eb, 'CFG', 'Centrifuge'],
    [0x800002ee, 'XPRT', 'Persistence'],
    [0x800002f1, '', 'Age X25519 Encryption'],
    [0x800002f2, '', 'Age NIST Encryption'],
    [0x800002f5, 'HONEY', 'HoneyWood'],
    [0x80000300, 'BALLZ', 'Ballzcoin'],
    [0x80000302, 'COSA', 'Cosanta'],
    [0x80000303, 'BR', 'BR'],
    [0x80000305, 'CSB', 'CosmoBliss'],
    [0x80000307, 'PLSR', 'Pulsar Coin'],
    [0x80000308, 'KEY', 'Keymaker Coin'],
    [0x80000309, 'BTW', 'Bitcoin World'],
    [0x8000030c, 'PLCUC', 'PLC Ultima Classic'],
    [0x8000030d, 'PLCUX', 'PLC Ultima X'],
    [0x8000030e, 'PLCU', 'PLC Ultima'],
    [0x8000030f, 'SMARTBC', 'SMART Blockchain'],
    [0x80000310, 'SUI', 'Sui'],
    [0x80000312, 'UIDD', 'UIDD'],
    [0x80000313, 'ACA', 'Acala'],
    [0x80000314, 'BNC', 'Bifrost'],
    [0x80000315, 'TAU', 'Lamden'],
    [0x8000031a, 'INTR', 'Interlay'],
    [0x8000031b, 'KINT', 'Kintsugi'],
    [0x8000031f, 'PDEX', 'Polkadex'],
    [0x80000320, 'BEET', 'Beetle Coin'],
    [0x80000321, 'DST', 'DSTRA'],
    [0x80000322, 'CY', 'Cyberyen'],
    [0x80000323, 'RYME', 'Ryme Network'],
    [0x80000324, 'ZKS', 'zkSync'],
    [0x80000328, 'QVT', 'Qvolta'],
    [0x80000329, 'SDN', 'Shiden Network'],
    [0x8000032a, 'ASTR', 'Astar Network'],
    [0x8000032b, '---', 'reserved'],
    [0x8000032d, 'MEER', 'Qitmeer'],
    [0x80000330, 'FSC', 'FSC'],
    [0x80000332, 'VET', 'VeChain Token'],
    [0x80000333, 'REEF', 'Reef'],
    [0x80000334, 'CLO', 'Callisto'],
    [0x80000336, 'BDB', 'BigchainDB'],
    [0x80000338, 'RBNT', 'Redbelly Network'],
    [0x8000033b, 'ACE', 'Endurance'],
    [0x8000033c, 'CCN', 'ComputeCoin'],
    [0x8000033d, 'BBA', 'BBACHAIN'],
    [0x8000033f, 'CRUZ', 'cruzbit'],
    [0x80000340, 'SAPP', 'Sapphire'],
    [0x80000341, '777', 'Jackpot'],
    [0x80000342, 'KYAN', 'Kyanite'],
    [0x80000343, 'AZR', 'Azzure'],
    [0x80000344, 'CFL', 'CryptoFlow'],
    [0x80000345, 'DASHD', 'Dash Diamond'],
    [0x80000346, 'TRTT', 'Trittium'],
    [0x80000347, 'UCR', 'Ultra Clear'],
    [0x80000348, 'PNY', 'Peony'],
    [0x80000349, 'BECN', 'Beacon'],
    [0x8000034a, 'MONK', 'Monk'],
    [0x8000034b, 'SAGA', 'CryptoSaga'],
    [0x8000034c, 'SUV', 'Suvereno'],
    [0x8000034d, 'ESK', 'EskaCoin'],
    [0x8000034e, 'OWO', 'OneWorld Coin'],
    [0x8000034f, 'PEPS', 'PEPS Coin'],
    [0x80000350, 'BIR', 'Birake'],
    [0x80000351, 'MOBIC', 'MobilityCoin'],
    [0x80000352, 'FLS', 'Flits'],
    [0x80000353, 'FRECO', 'Freco'],
    [0x80000354, 'DSM', 'Desmos'],
    [0x80000355, 'PRCY', 'PRCY Coin'],
    [0x8000035a, 'HVH', 'HAVAH'],
    [0x80000362, 'MOB', 'MobileCoin'],
    [0x80000364, 'IF', 'Infinitefuture'],
    [0x8000036d, 'NAM', 'Namada'],
    [0x8000036e, 'SCR', 'Scorum Network'],
    [0x80000370, 'LUM', 'Lum Network'],
    [0x80000373, 'ZBC', 'ZooBC'],
    [0x80000376, 'ADF', 'AD Token'],
    [0x80000378, 'NEO', 'NEO'],
    [0x80000379, 'TOMO', 'TOMO'],
    [0x8000037a, 'XSEL', 'Seln'],
    [0x80000380, 'LKSC', 'LKSCoin'],
    [0x80000382, 'AS', 'Assetchain'],
    [0x80000383, 'XEC', 'eCash'],
    [0x80000384, 'LMO', 'Lumeneo'],
    [0x80000385, 'NXT', 'NxtMeta'],
    [0x80000388, 'HNT', 'Helium'],
    [0x8000038a, 'XPX', 'Sirius'],
    [0x8000038b, 'FIS', 'StaFi'],
    [0x8000038d, 'SGE', 'Saage'],
    [0x8000038f, 'GERT', 'Gert'],
    [0x80000391, 'VARA', 'Vara Network'],
    [0x80000394, 'META', 'Metadium'],
    [0x80000395, 'FRA', 'Findora'],
    [0x80000397, 'CCD', 'Concordium'],
    [0x80000399, 'AVN', 'Avian Network'],
    [0x8000039d, 'DIP', 'Dipper Network'],
    [0x800003a0, 'GHM', 'HermitMatrixNetwork'],
    [0x800003a3, 'RUNE', 'THORChain (RUNE)'],
    [0x800003aa, 'MGO', 'Mango Network'],
    [0x800003ab, 'AB', 'Argot Protocol'],
    [0x800003ad, '---', 'reserved'],
    [0x800003ae, 'KCN', 'Kylacoin'],
    [0x800003af, 'LCN', 'Lyncoin'],
    [0x800003b1, 'UNLOCK', 'Jasiri protocol'],
    [0x800003bb, 'LTP', 'LifetionCoin'],
    [0x800003be, '', 'KickSoccer'],
    [0x800003c0, 'VKAX', 'Vkax'],
    [0x800003c6, 'MATIC', 'Matic'],
    [0x800003c8, 'UNW', 'UNW'],
    [0x800003c9, 'QI', 'Quai Network'],
    [0x800003ca, 'TWINS', 'TWINS'],
    [0x800003d1, 'TLOS', 'Telos'],
    [0x800003d5, 'TAFECO', 'Taf ECO Chain'],
    [0x800003d9, 'AU', 'Autonomy'],
    [0x800003db, 'VCG', 'VipCoin'],
    [0x800003dc, 'XAZAB', 'Xazab core'],
    [0x800003dd, 'AIOZ', 'AIOZ'],
    [0x800003de, 'CORE', 'Coreum'],
    [0x800003df, 'PEC', 'Phoenix'],
    [0x800003e0, 'UNT', 'Unit'],
    [0x800003e1, 'XRB', 'X Currency'],
    [0x800003e2, 'QUAI', 'Quai Network'],
    [0x800003e3, 'CAPS', 'Ternoa'],
    [0x800003e4, 'OKT', 'OKChain Token'],
    [0x800003e5, 'SUM', 'Solidum'],
    [0x800003e6, 'LBTC', 'Lightning Bitcoin'],
    [0x800003e7, 'BCD', 'Bitcoin Diamond'],
    [0x800003e8, 'BTN', 'Bitcoin New'],
    [0x800003e9, 'TT', 'ThunderCore'],
    [0x800003ea, 'BKT', 'BanKitt'],
    [0x800003eb, 'NODL', 'Nodle'],
    [0x800003ec, 'PCOIN', 'PCOIN'],
    [0x800003ed, 'TAO', 'Bittensor'],
    [0x800003ee, 'HSK', 'HashKey Chain'],
    [0x800003ef, 'FTM', 'Fantom'],
    [0x800003f0, 'RPG', 'RPG'],
    [0x800003f1, 'LAKE', 'iconLake'],
    [0x800003f2, 'HT', 'Huobi ECO Chain'],
    [0x800003f3, 'ELV', 'Eluvio'],
    [0x800003f4, 'JOC', 'Japan Open Chain'],
    [0x800003f5, 'BIC', 'Beincrypto'],
    [0x800003f6, 'JOY', 'Joystream'],
    [0x800003f8, '---', 'reserved'],
    [0x800003fc, 'EVC', 'Evrice'],
    [0x800003fe, 'XRD', 'Radix DLT'],
    [0x800003ff, 'ONE', 'HARMONY-ONE (Legacy)'],
    [0x80000400, 'ONT', 'Ontology'],
    [0x80000401, 'CZZ', 'Classzz'],
    [0x80000402, 'KEX', 'Kira Exchange Token'],
    [0x80000403, 'MCM', 'Mochimo'],
    [0x80000404, 'PLS', 'Pulse Coin'],
    [0x80000408, 'BTCR', 'BTCR'],
    [0x80000412, 'MFID', 'Moonfish ID'],
    [0x80000457, 'BBC', 'Big Bitcoin'],
    [0x8000045c, 'CORE', 'Core'],
    [0x80000460, 'RISE', 'RISE'],
    [0x80000462, 'CMT', 'CyberMiles Token'],
    [0x80000468, 'ETSC', 'Ethereum Social'],
    [0x80000469, 'DFI', 'DeFiChain'],
    [0x8000046a, 'DFI', 'DeFiChain EVM Network'],
    [0x80000471, '$DAG', 'Constellation Labs'],
    [0x80000479, 'CDY', 'Bitcoin Candy'],
    [0x80000483, 'ENJ', 'Enjin Coin'],
    [0x80000492, 'HOO', 'Hoo Smart Chain'],
    [0x800004d2, 'ALPH', 'Alephium'],
    [0x800004d4, '', 'Masca'],
    [0x800004d5, '', 'Nostr'],
    [0x80000500, '', 'Kudos Setler'],
    [0x80000504, 'GLMR', 'Moonbeam'],
    [0x80000505, 'MOVR', 'Moonriver'],
    [0x80000512, 'WPC', 'Wpc'],
    [0x8000051c, 'WEI', 'WEI'],
    [0x80000520, 'BITS', 'Entropy'],
    [0x80000539, 'DFC', 'Defcoin'],
    [0x8000053a, 'IRON', 'Iron Fish'],
    [0x80000544, 'ISLM', 'IslamicCoin'],
    [0x80000575, 'HYC', 'Hycon'],
    [0x80000582, 'TENTSLP', 'TENT Simple Ledger Protocol'],
    [0x800005e6, 'XSC', 'XT Smart Chain'],
    [0x800005e8, 'AAC', 'Double-A Chain'],
    [0x800005f4, '', 'Taler'],
    [0x800005fd, 'BEAM', 'Beam'],
    [0x8000060f, 'SDK', 'Sovereign SDK'],
    [0x80000613, 'APC', 'Apc Chain'],
    [0x80000650, 'ELF', 'AELF'],
    [0x80000652, 'AUDL', 'AUDL'],
    [0x80000654, 'ATH', 'Atheios'],
    [0x8000065b, 'LUME', 'Lume Web'],
    [0x8000066a, 'NEW', 'Newton'],
    [0x80000679, 'BTA', 'Btachain'],
    [0x80000684, 'NEOX', 'Neoxa'],
    [0x80000685, 'MEWC', 'Meowcoin'],
    [0x80000698, 'BCX', 'BitcoinX'],
    [0x800006c1, 'XTZ', 'Tezos'],
    [0x800006f0, 'LBTC', 'Liquid BTC'],
    [0x800006f1, 'BBP', 'Biblepay'],
    [0x800006f8, 'JPYS', 'JPY Stablecoin'],
    [0x800006fd, 'VEGA', 'Vega Protocol'],
    [0x80000717, 'ADA', 'Cardano'],
    [0x8000071a, 'CUBE', 'Cube Chain Native Token'],
    [0x80000743, 'TES', 'Teslacoin'],
    [0x80000760, 'ZTX', 'Zetrix'],
    [0x8000076b, 'XEC', 'eCash token'],
    [0x8000076c, 'XNA', 'Neurai'],
    [0x8000076d, 'CLC', 'Classica'],
    [0x80000773, 'BITCI', 'Bitcicoin'],
    [0x8000077f, 'VIPS', 'VIPSTARCOIN'],
    [0x80000786, 'CITY', 'City Coin'],
    [0x800007a3, 'XX', 'xx coin'],
    [0x800007b9, 'XMX', 'Xuma'],
    [0x800007c0, 'TRTL', 'TurtleCoin'],
    [0x800007c1, 'SLRT', 'Solarti Chain'],
    [0x800007c2, 'QTH', 'Qing Tong Horizon'],
    [0x800007c3, 'EGEM', 'EtherGem'],
    [0x800007c4, 'MIRA', 'Mira Chain'],
    [0x800007c5, 'HODL', 'HOdlcoin'],
    [0x800007c6, 'PHL', 'Placeholders'],
    [0x800007c7, 'SC', 'Sia'],
    [0x800007cc, 'MYT', 'Mineyourtime'],
    [0x800007cd, 'POLIS', 'Polis'],
    [0x800007ce, 'XMCC', 'Monoeci'],
    [0x800007cf, 'COLX', 'ColossusXT'],
    [0x800007d0, 'GIN', 'GinCoin'],
    [0x800007d1, 'MNP', 'MNPCoin'],
    [0x800007d2, 'MLN', 'Miraland'],
    [0x800007e1, 'KIN', 'Kin'],
    [0x800007e2, 'EOSC', 'EOSClassic'],
    [0x800007e3, 'GBT', 'GoldBean Token'],
    [0x800007e4, 'PKC', 'PKC'],
    [0x800007e5, 'SKT', 'Sukhavati'],
    [0x800007e8, 'USBC', 'Universal Ledger USBC'],
    [0x800007e6, 'XHT', 'Xinghuo Token'],
    [0x800007e7, 'COC', 'Chat On Chain'],
    [0x800007e9, 'ROCK', 'Zenrock Labs'],
    [0x800007fe, 'ANY', 'Any'],
    [0x80000800, 'MCASH', 'MCashChain'],
    [0x80000801, 'TRUE', 'TrueChain'],
    [0x80000802, 'MOVO', 'Movo Smart Chain'],
    [0x80000826, 'KILT', 'KILT Spiritnet'],
    [0x8000083d, 'SAMA', 'Exosama Network'],
    [0x80000840, 'IoTE', 'IoTE'],
    [0x8000084d, 'BAY', 'BitBay'],
    [0x80000859, 'XRG', 'Ergon'],
    [0x80000897, 'SAMA', 'Moonsama Network'],
    [0x800008ad, 'ASK', 'ASK'],
    [0x800008ae, 'CWEB', 'Coinweb'],
    [0x800008ed, '', 'Qiyi Chain'],
    [0x800008fd, 'QTUM', 'QTUM'],
    [0x800008fe, 'ETP', 'Metaverse'],
    [0x800008ff, 'GXC', 'GXChain'],
    [0x80000900, 'CRP', 'CranePay'],
    [0x80000901, 'ELA', 'Elastos'],
    [0x80000922, 'SNOW', 'Snowblossom'],
    [0x8000093d, 'XIN', 'Mixin'],
    [0x800009c4, 'NEXI', 'Nexi'],
    [0x80000a0a, 'AOA', 'Aurora'],
    [0x80000a7e, 'AIPG', 'AIPowerGrid'],
    [0x80000a9e, 'NAS', 'Nebulas'],
    [0x80000b4e, 'REOSC', 'REOSC Ecosystem'],
    [0x80000b7d, 'BND', 'Blocknode'],
    [0x80000bb8, 'SM', 'Stealth Message'],
    [0x80000bbb, 'LUX', 'LUX'],
    [0x80000bd6, 'HBAR', 'Hedera HBAR'],
    [0x80000c05, 'COS', 'Contentos'],
    [0x80000ccc, 'CCC', 'CodeChain'],
    [0x80000d05, 'SXP', 'Solar'],
    [0x80000d0a, 'PEAQ', 'peaq'],
    [0x80000d31, 'ROI', 'ROIcoin'],
    [0x80000d35, 'DYN', 'Dynamic'],
    [0x80000d37, 'SEQ', 'Sequence'],
    [0x80000d6a, 'PEPE', 'Pepecoin Core'],
    [0x80000dad, 'JFIN', 'JFIN Coin'],
    [0x80000de0, 'DEO', 'Destocoin'],
    [0x80000dec, 'DST', 'DeStream'],
    [0x80000e11, 'CY', 'Cybits'],
    [0x80000ead, 'MPC', 'Partisia Blockchain'],
    [0x80000fc8, 'FC8', 'FCH Network'],
    [0x80001000, 'YEE', 'YeeCo'],
    [0x8000107a, 'IOTA', 'IOTA'],
    [0x8000107b, 'SMR', 'Shimmer'],
    [0x80001092, 'AXE', 'Axe'],
    [0x800010f7, 'XYM', 'Symbol'],
    [0x8000115c, 'C4E', 'Chain4Energy'],
    [0x80001337, 'XVM', 'Venidium'],
    [0x80001387, 'BXN', 'BlackFort Exchange Network'],
    [0x80001388, 'V12', 'Vet The Vote'],
    [0x8000138e, 'SBC', 'Senior Blockchain'],
    [0x80001480, 'FIC', 'FIC'],
    [0x800014e9, 'HNS', 'Handshake'],
    [0x8000151c, 'ISK', 'ISKRA'],
    [0x8000155b, 'ALTME', 'ALTME'],
    [0x800015b3, 'FUND', 'Unification'],
    [0x8000167d, 'STX', 'Stacks'],
    [0x80001707, 'VOW', 'VowChain VOW'],
    [0x80001720, 'SLU', 'SILUBIUM'],
    [0x8000176B, 'DUSK', 'Dusk Network'],
    [0x800017ac, 'GO', 'GoChain GO'],
    [0x80001800, 'DTS', 'Datos'],
    [0x8000181e, 'MOI', 'My Own Internet'],
    [0x80001886, 'STEAMX', 'Rails Network Mainnet'],
    [0x80001984, 'UM', 'Penumbra'],
    [0x800019c7, 'RSC', 'Royal Sports City'],
    [0x80001a0a, 'BPA', 'Bitcoin Pizza'],
    [0x80001a20, 'SAFE', 'SAFE'],
    [0x80001a7b, 'COTI', 'COTI'],
    [0x80001b39, 'ROGER', 'TheHolyrogerCoin'],
    [0x80001b58, 'ZETA', 'ZetaChain'],
    [0x80001b73, 'ELLA', 'Ella the heart'],
    [0x80001b74, 'AA', 'Arthera'],
    [0x80001bb3, 'TOPL', 'Topl'],
    [0x80001ca3, 'KLY', 'KLYNTAR'],
    [0x80001cad, 'SHFT', 'Shyft'],
    [0x80001d5e, 'MEV', 'MEVerse'],
    [0x80001d98, 'ADIL', 'ADIL Chain'],
    [0x80001e61, 'BTV', 'Bitvote'],
    [0x80001e63, 'CPV', 'Compverse'],
    [0x80001f40, 'SKY', 'Skycoin'],
    [0x80001f90, '', 'DSRV'],
    [0x80001ff5, 'BOC', 'BeOne Chain'],
    [0x80002000, 'PAC', 'pacprotocol'],
    [0x80002019, 'KLAY', 'KLAY'],
    [0x80002093, 'BTQ', 'BitcoinQuark'],
    [0x800020fc, 'XCH', 'Chia'],
    [0x80002148, '---', 'reserved'],
    [0x800021e8, 'PLMNT', 'Planetmint'],
    [0x800022a2, 'GGX', 'Golden Gate'],
    [0x800022b6, 'GGXT', 'Golden Gate Sydney'],
    [0x800022b8, 'SBTC', 'Super Bitcoin'],
    [0x80002304, 'NULS', 'NULS'],
    [0x80002325, 'BBC', 'Babacoin'],
    [0x80002326, 'JGC', 'JagoanCoin'],
    [0x80002327, 'BTP', 'Bitcoin Pay'],
    [0x80002328, 'AVAX', 'Avalanche'],
    [0x80002329, 'ARB1', 'Arbitrum'],
    [0x8000232a, 'BOBA', 'Boba'],
    [0x8000232b, 'LOOP', 'Loopring'],
    [0x8000232c, 'STRK', 'StarkNet'],
    [0x8000232d, 'AVAXC', 'Avalanche C-Chain'],
    [0x8000232e, 'BSC', 'Binance Smart Chain'],
    [0x80002645, 'NRG', 'Energi'],
    [0x800026a0, 'BTF', 'Bitcoin Faith'],
    [0x8000270f, 'GOD', 'Bitcoin God'],
    [0x80002710, 'FO', 'FIBOS'],
    [0x80002711, 'SPACE', 'Space'],
    [0x8000277f, 'DHP', 'dHealth'],
    [0x800027f2, 'RTM', 'Raptoreum'],
    [0x80002802, 'AA', 'Arthera'],
    [0x80002833, 'XRC', 'XRhodium'],
    [0x8000290b, 'NUM', 'Numbers Protocol'],
    [0x8000296d, 'XPI', 'Lotus'],
    [0x80002b67, 'ESS', 'Essentia One'],
    [0x80002dde, 'VARCH', 'InvArch'],
    [0x80002ddf, 'TNKR', 'Tinkernet'],
    [0x80003039, 'IPOS', 'IPOS'],
    [0x8000312a, 'MINA', 'Mina'],
    [0x80003333, 'BTY', 'BitYuan'],
    [0x80003334, 'YCC', 'Yuan Chain Coin'],
    [0x80003445, 'PHX', 'Phoenix'],
    [0x800036b1, 'WAX', 'Worldwide Asset Exchange'],
    [0x80003de5, 'SDGO', 'SanDeGo'],
    [0x80003f35, 'XTX', 'Totem Live Network'],
    [0x80004172, 'ARDR', 'Ardor'],
    [0x80004650, 'MTR', 'Meter'],
    [0x80004add, 'SAFE', 'Safecoin'],
    [0x80004adf, 'FLUX', 'Flux'],
    [0x80004ae1, 'RITO', 'Ritocoin'],
    [0x80004d4c, 'ML', 'Mintlayer'],
    [0x80004e44, 'XND', 'ndau'],
    [0x8000520c, 'C4EI', 'c4ei'],
    [0x80005359, 'XAH', 'Xahau'],
    [0x80005580, 'PAC', 'Pactus'],
    [0x800057e8, 'PWR', 'PWRcoin'],
    [0x800059d8, 'EPIC', 'Epic Cash'],
    [0x800062a4, 'BELL', 'Bellcoin'],
    [0x80006476, 'CHX', 'Own'],
    [0x80006731, 'G1', 'Ğ1'],
    [0x80007227, 'NEXA', 'Nexa'],
    [0x80007531, '---', 'reserved'],
    [0x8000797e, 'ESN', 'EtherSocial Network'],
    [0x80007a69, '', 'ThePower'],
    [0x80008288, 'TEO', 'Trust Eth reOrigin'],
    [0x80008456, 'BTCS', 'Bitcoin Stake'],
    [0x80008888, 'BTT', 'ByteTrade'],
    [0x80009468, 'FXTC', 'FixedTradeCoin'],
    [0x80009999, 'AMA', 'Amabig'],
    [0x8000a455, 'FACT', 'FACT0RN'],
    [0x8000a814, 'AXIV', 'AXIV'],
    [0x8000babb, 'BAX', 'BAX'],
    [0x8000c06e, 'EVE', 'evan'],
    [0x8000c0c0, 'STASH', 'STASH'],
    [0x8000ce10, 'CELO', 'Celo'],
    [0x8000f0b0, 'TH', 'TianHe'],
    [0x80010000, 'KETH', 'Krypton World'],
    [0x80010f2c, 'GRLC', 'Garlicoin'],
    [0x80011177, 'GWL', 'Gewel'],
    [0x8001455d, 'QUBIC', 'Qubic'],
    [0x80012fd1, 'ZYN', 'Wethio'],
    [0x80015b38, 'RYO', 'c0ban'],
    [0x8001869f, 'WICC', 'Waykichain'],
    [0x80018894, 'HOME', 'HomeCoin'],
    [0x80018a92, 'STC', 'Starcoin'],
    [0x800196ad, '', 'Seed Hypermedia'],
    [0x80019a91, 'STRAX', 'Strax'],
    [0x8001b207, 'KAS', 'Kaspa'],
    [0x8001d9f9, 'KLS', 'Karlsen'],
    [0x8002780b, 'APTA', 'Bloqs4Good'],
    [0x80030fb1, 'AKA', 'Akroma'],
    [0x80036cb1, 'CONET', 'CONET Holesky Network'],
    [0x8003c301, 'ATS', 'ARTIS sigma1'],
    [0x8003fc0b, 'ZAMA', 'Zama'],
    [0x8004cb2f, 'PI', 'Pi Network'],
    [0x80051614, 'VALUE', 'Value Chain'],
    [0x80051615, '3333', 'Pi Value Consensus'],
    [0x80067932, 'X42', 'x42'],
    [0x80082750, 'SCR', 'Scroll'],
    [0x800a2c2a, 'VITE', 'Vite'],
    [0x800b3206, 'ICE', 'Ice Network'],
    [0x800d9038, 'SEA', 'Second Exchange Alliance'],
    [0x800c9061, 'WTC', 'WaltonChain'],
    [0x80100000, 'AMAX', 'Armonia Meta Chain'],
    [0x8011df89, 'ILT', 'iOlite'],
    [0x8014095a, 'ETHO', 'Etho Protocol'],
    [0x80140adc, 'XERO', 'Xerom'],
    [0x801a2010, 'LAX', 'LAPO'],
    [0x803be02b, 'EPK', 'EPIK Protocol'],
    [0x80485944, 'HYD', 'Hydra Token'],
    [0x80501949, 'BCO', 'BitcoinOre'],
    [0x8050194a, 'BHD', 'BitcoinHD'],
    [0x8050544e, 'PTN', 'PalletOne'],
    [0x80564c58, 'VLX', 'Velas'],
    [0x8057414e, 'WAN', 'Wanchain'],
    [0x80579bfc, 'WAVES', 'Waves'],
    [0x80579bfd, 'WEST', 'Waves Enterprise'],
    [0x80616263, 'ABC', 'Abcmint'],
    [0x8063726d, 'CRM', 'Creamcoin'],
    [0x806d6e52, 'BROCK', 'Bitrock'],
    [0x8073656d, 'SEM', 'Semux'],
    [0x80737978, 'ION', 'ION'],
    [0x8076adf1, 'FCT', 'FirmaChain'],
    [0x80776772, 'WGR', 'WGR'],
    [0x80776773, 'OBSR', 'OBServer'],
    [0x807c8fc7, 'AFS', 'ANFS'],
    [0x80e6b280, 'XDS', 'XDS'],
    [0x83adbc39, 'AQUA', 'Aquachain'],
    [0x854c5638, 'HATCH', 'Hatch'],
    [0x857ab1e1, 'kUSD', 'kUSD'],
    [0x85f5e0fc, 'GENS', 'GENS'],
    [0x85f5e0fd, 'EQ', 'EQ'],
    [0x85f5e0fe, 'FLUID', 'Fluid Chains'],
    [0x85f5e0ff, 'QKC', 'QuarkChain'],
    [0x80abcdef, 'LBR', '0L'],
    [0x8134afd5, 'ROH', 'Rooch'],
    [0x8134d82e, 'NLK', 'NuLinkCoin'],
    [0xa4465644, 'FVDC', 'ForumCoin'],
    [0xc655454c, '', 'Fuel'],
    [0xc655454d, 'TTNC', 'TakeTitan'],
    [0xc6554557, 'MTGBP', 'MTGBP'],
    [0xc6554561, 'QFS', 'Qfs']
]

export default coin_types
