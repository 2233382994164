import DialogSwap from "@/components/Dialogs/DialogSwap/DialogSwap"

export default function SwapPage() {

    return <div>
        <DialogSwap>
            <div>
                swap
            </div>
        </DialogSwap>

    </div>
}
